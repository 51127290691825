import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService  } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private apiUrl = `${environment.apiUrl}/calendar`;

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) { }

  generateShareLink(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
  
    return this.http.post<{ success: boolean, link?: string, message?: string }>(`${this.apiUrl}/generate`, data, { headers }).pipe(
      tap(response => {
        if (response.success) {
        } else {
          throw new Error(response.message);
        }
      }),
      catchError(error => {
        console.error('Error generating share link:', error);
        return of({ success: false, message: error.message });
      })
    );
  }
  
  getUnavailableSlots(shareKey: string): Observable<any> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
    
    return this.http.get<{ success: boolean, unavailableSlots?: any[], slotDuration?: number, message?: string }>(`${this.apiUrl}/unavailableSlots/${shareKey}`, { headers }).pipe(
      tap(response => {
        if (!response.success) {
          throw new Error(response.message);
        }
      }),
      catchError(this.handleError.bind(this))
    );
  }
  
  bookSlot(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
  
    return this.http.post<{ success: boolean, activityId?: string, message?: string }>(`${this.apiUrl}/bookSlot`, data, { headers }).pipe(
      tap(response => {
        if (response.success) {
          this.toastr.clear();
        }
      }),
      catchError(error => {
        this.toastr.clear();
        if (error instanceof HttpErrorResponse) {
          if (error.status === 429) {
            this.toastr.warning('Vous avez déjà fait une réservation il y a moins de 30 minutes. Veuillez patienter.', 'Réservation trop fréquente', {
              timeOut: 5000,
              positionClass: 'toast-top-right'
            });
          } else {
            this.toastr.error('Une erreur est survenue lors de la réservation du créneau.', 'Erreur', {
              timeOut: 5000,
              positionClass: 'toast-top-right'
            });
          }
        } else {
          this.toastr.error('Une erreur est survenue lors de la réservation du créneau.', 'Erreur', {
            timeOut: 5000,
            positionClass: 'toast-top-right'
          });
        }
        console.error('Error booking slot:', error);
        return EMPTY;
      })
    );
  }  
  
  clearToastrNotifications() {
    this.toastr.clear();
  }

  getCalendarSettings(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });

    return this.http.get<{ success: boolean, data?: any, message?: string }>(`${this.apiUrl}/settings`, { headers }).pipe(
      tap(response => {
        if (!response.success) {
          throw new Error(response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching calendar settings:', error);
        return of({ success: false, message: error.message });
      })
    );
  }

  updateCalendarSettings(data: any): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
  
    return this.http.patch<{ success: boolean, data?: any, message?: string }>(`${this.apiUrl}/settings`, data, { headers }).pipe(
      tap(response => {
        if (!response.success) {
          throw new Error(response.message);
        }
      }),
      catchError(error => {
        console.error('Error updating calendar settings:', error);
        return of({ success: false, message: error.message });
      })
    );
  }  

  getSharedCalendarSettings(shareKey: string): Observable<any> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
  
    return this.http.get<{ success: boolean, data?: any, message?: string }>(`${this.apiUrl}/sharedSettings/${shareKey}`, { headers }).pipe(
      tap(response => {
        if (!response.success) {
          throw new Error(response.message);
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  confirmBooking(activityId: string, confirmationCode: string): Observable<any> {
    const data = { activityId, confirmationCode };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    return this.http.post<{ success: boolean, message?: string }>(`${this.apiUrl}/confirm-booking`, data, { headers }).pipe(
      tap(response => {
        if (!response.success) {
          console.error('Confirmation response error:', response.message);
          throw new Error(response.message);
        }
      }),
      catchError(error => {
        console.error('Error confirming booking:', error);
        return of({ success: false, message: error.message });
      })
    );
  }  

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 404) {
      this.router.navigate(['/share-revoked']);
    }
    return throwError(error);
  }
}
